@import "../../utils/variables.scss";
.footer {
  position:relative;
  width:100%;
  padding: 10px 0;
  background-color:#FFF;
  color:#000;
  box-shadow: 0 0 10px #8888;
  z-index:99;
  & a {
      text-decoration: underline;
  }
  & .footer-inner {
    @include typoE;
    & p:first-child {
      margin-top:0;
    }
    & p:last-child {
      margin-bottom:0;
    }
    margin-left:140px;
    width: calc( 100% - 280px );
    @include xl {
        margin-left:50px;
        width: calc( 100% - 100px );
    }
    @include lg {
        margin-left:15px;
        width: calc( 100% - 30px );
    }
  }
  & .footer-baseline {
      display:inline-block;
      padding:5px;
  }
  &.clone .footer-baseline {
      cursor:pointer;
      &:hover {
          color:$hover;
          background:#000;
      }
  }
  & .footer-plus {
    @include typoD;
    margin-top:30px;
    border-top:1px solid #000;
    padding-top:15px;
    & h3 {
      @include typoB;
      margin:0;
    }
  }
  &.clone {
    display:none;
  }
  &.clone.show {
    display:block;
    position:fixed;
    top:calc(100% + 20px);
    left:0;
    z-index:99;
    cursor: pointer;
  }
  &.clone.anim {
    transition: top 0.2s;
  }
  &.clone.top {
    top:calc(100% - 47px);
  }
  .rs {
    filter: grayscale(1);
    display: flex;
    align-items: center;
    text-transform: uppercase;
    a {
      margin-right:10px;
      height:30px;
      &.news {
        height:auto;
      }
    }
  }
  .footer-inner-cartouche {
    line-height:30px;
  }
}
#footer {
  margin-top:100px;
  overflow:hidden;
}
svg.insta {
  width:30px;
  height:30px;
  border-radius:15px;
  fill:#555;
}
