@import "../../utils/variables.scss";
.cartouche-infos{
  position: relative;
  padding-right:15px;
  &.splitstat {
      padding-top:10px;
  }
  & .cartouche-infos-label {
    @include typoE;
    padding-top: 5px;
  }
  & .cartouche-infos-value {
    &.size-big {
      @include typoA;
    }
    &.size-medium {
      @include typoB;
    }
    &.size-small {
      margin-top:5px;
      @include typoD;
    }
    &.size-xsmall {
      @include typoE;
    }
    width:100%;
    & a {
      text-decoration: none;
      color:#000;
      &:hover {
        color:$hover;
      }
    }
    p:first-child {
      margin-top: 5px;
    }
  }
  & .cartouche-infos-texte {
    @include typoD;
    margin:30px 0;
  }
  & .cartouche-infos-icon {
    position:absolute;
    right:0;
    top:17px;
    width: 26px;
    height: 26px;
    // & svg {
    //   width:100%;
    //   height:100%;
    // }
  }
  &.splitstat {
    & .cartouche-infos-value {
      white-space: nowrap;
      overflow:hidden;
      text-overflow: ellipsis;
    }
  }
  & .cartouche-infos-image {
    margin:20px 0;
    img {
      width:100%;
    }
  }
  & .cartouche-infos-animation {
    margin:20px 0;
  }
  & .cartouche-infos-image-no-crop {
    position:relative;
    width:100%;
    padding-bottom:56.25%;
    & img {
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      object-fit: contain;
      object-position:left;
    }
  }
}
