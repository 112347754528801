@import "../../utils/variables.scss";
.mod {
  position:absolute;
  right:0;
  top:15px;
  z-index:100000;
  height:100%;
  pointer-events: none;
  .mod-inner {
    position:sticky;
    top:70px;
    button {
      pointer-events: all;
    }
  }
}
