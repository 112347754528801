@import "../../utils/variables.scss";
.ac-instance-bloc {
  border-top: 1px solid #000;
  padding:0 0 30px 0;
  &.mini {
    padding:0;
  }
  position:relative;
}
.ac-project-tile {
  padding: 10px;
	color: #FFF;
	background-color: #000;
  font-size: 35px;
  line-height: 36px;
  font-weight: 800;
  &:hover {
    color:$hover;
    background-color: #FFF;
  }
}
.ac-instance-tile {
  position:relative;
  border-top: 1px solid #000;
  padding:5px 0;
  .ac-instance-tile-type {
    @include typoE;
    min-height:1.3em;
  }
  .ac-instance-tile-head {
    @include typoB;
  }
  .ac-instance-tile-titre {
    @include typoB;
    margin-bottom: 5px;
  }
  .ac-instance-tile-mini-titre {
    @include typoB;
  }
  .ac-instance-tile-type {

  }
  .ac-instance-tile-visuel {
    padding:10px 0;
  }
  .ac-instance-tile-dates {
    @include typoB;
  }
  .ac-instance-tile-mini-dates {
    @include typoD;
  }
  .ac-instance-tile-lieu {
    @include typoB;
  }
  .ac-instance-tile-mini-lieu {
    margin-top: 5px;
    @include typoC;
  }
  .ac-instance-tile-ville {
    @include typoB;
  }
  .ac-instance-tile-resume {
    @include typoD;
  }
  .ac-instance-tile-mini-resume {
    @include typoE;
    p:first-child {
      margin-top:5px;
    }
  }
  &:hover {
    color:$hover;
    border-top: 1px solid $hover;
    img {
      filter:saturate(1.2);
    }
  }
}
.menu-ac {
  @include typoC;
  &.active a {
    color:$hover;
  }
}
.ac-instance {
  position:relative;

  .ac-instance-texte {
    @include typoD;
    a {
      text-decoration: underline;
    }
    overflow:hidden;
  }
  .ac-instance-spacer {
    @include typoE;
  }
  .ac-instance-type {
    @include typoE;
    padding-top:5px;
  }
  .ac-instance-titre {
    @include typoB;
  }
  .ac-instance-artiste {
    @include typoB;
  }
  .ac-instance-mini-titre {
    @include typoB;
  }
  .ac-instance-visuel {
    padding:10px 0;
  }
  .ac-instance-resume {
    @include typoD;
  }
  .ac-instance-mini-resume {
    @include typoE;
    p:first-child {
      margin-top:5px;
    }
  }
  .type-billet {
    @include typoC;
  }
}
.ac-restitution {
  position:relative;
  border-top: 1px solid #000;
  padding:5px 0;
  .ac-restitution-titre-instance {
    @include typoB;
  }
  .ac-restitution-artiste {
    @include typoC;
  }
  .ac-restitution-titre {
    @include typoB;
  }
  .ac-restitution-dates {
    @include typoB;
  }
  .ac-restitution-dates {
    @include typoD;
  }
  .ac-restitution-lieu {
    margin-top: 5px;
    @include typoC;
  }
  .ac-restitution-resume {
    @include typoD;
    p:first-child {
      margin-top:5px;
    }
  }
}
.ac-type {
  @include typoE;
  padding-top:5px;
}
.ac-instance-diaporama {
  margin-top:15px;
  width:100%;
  position: relative;
  &>div.swiper-container {
    position: relative;
    width:100%;
  }
  & .swiper-slide img {
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    object-fit: contain;
    object-position: center;
  }
  .images-player-compteur {
    position:absolute;
    top:5px;
    right:5px;
    cursor:pointer;
    border-radius: 4px;
    color:#FFF;
    text-shadow: 0 0 10px #000;
    padding:5px;
    z-index:10;
    user-select: none;
    @include typoF;
  }
}
.ac {
  position:relative;

  .ac-sidebar {
    position:sticky;
    top:60px;
  }
  .ac-text, .liste {
    @include typoD;
    a {
      text-decoration: underline;
    }
  }
  .ac-ressources {
    @include typoB;
    margin:0;
    &:hover {
      color:var(--hover-color);
      background-color: #FFF;
    }
  }
}
.ac-project {
  position:relative;
  .ac-project-sidebar {
    position:sticky;
    top:60px;
  }
  .ac-text, .liste {
    @include typoD;
    a {
      color:$hover;
    }
  }
}
