@import "../../utils/variables.scss";
.animation {
    position:relative;
    width:100%;
    padding-bottom:50%;
    & img {
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:auto;
      opacity:0;
      transition:opacity 0.2s;
      &.visible {
        opacity:1;
      }
      &:last-child {
        mix-blend-mode: multiply;
      }
    }

}
