.expositions {
  position: relative;
  .expositions-a-venir {
    position:sticky;
    top:60px;
    &.nosticky {
      position:relative;
      top:0;
    }
  }
}
