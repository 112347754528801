@import "../../utils/variables.scss";
.rdv {
  position:relative;

  & .rdv-dates {
    @include typoC;
  }
  & .rdv-texte {
    a {
      text-decoration:underline;
      overflow:hidden;
    }
    @include typoD;
  }
  & .rdv-spacer {
    @include typoE;
  }
  & .rdv-type {
    @include typoE;
  }
  & .rdv-titre {
    @include typoB;
  }
  & .rdv-artiste {
    @include typoB;
  }
  & .rdv-mini-titre {
    @include typoB;
  }
  & .rdv-type {

  }
  & .rdv-visuel {
    padding:10px 0;
  }
  & .rdv-lieu {
    @include typoB;
  }
  & .rdv-mini-lieu {
    margin-top: 5px;
    @include typoC;
  }
  & .rdv-ville {
    @include typoB;
  }
  & .rdv-resume {
    @include typoD;
  }
  & .rdv-mini-resume {
    @include typoE;
    & p:first-child {
      margin-top:5px;
    }
  }
}
.rdvs {
  & .filter-type {
    @include typoB;
    cursor:pointer;
    user-select: none;
    & svg .to-stroke {
      stroke:#000;
    }
    &.active svg .active-fill {
      fill:$hover;
    }
    &:hover{
      color:$hover;
      & svg .to-stroke {
        stroke:$hover;
      }
    }
    &.archives {
      margin-top:30px;
    }
  }
}
.rdv-diaporama {
  margin-top:15px;
  width:100%;
  position: relative;
  &>div.swiper-container {
    position: relative;
    width:100%;
  }
  & .swiper-slide img {
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    object-fit: contain;
    object-position: center;
  }
  .images-player-compteur {
    position:absolute;
    top:5px;
    right:5px;
    cursor:pointer;
    border-radius: 4px;
    color:#FFF;
    text-shadow: 0 0 10px #000;
    padding:5px;
    z-index:10;
    user-select: none;
    @include typoF;
  }
}
.rdvs {
  position: relative;
  .filtres {
    position:sticky;
    top:60px;
  }
}
