@import "../../utils/variables.scss";
.image-container {
  width:100%;
  position:relative;
  &.back-white {
    background-color: #fff;
  }
  &.back-black {
    background-color: #000;
  }
  img {
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    object-fit: contain;
    object-position: center;
  }
  .image-placeholder {
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    background-color: #eee;
  }
}
.image-credits {
  @include typoE;
}
