@import "../../utils/variables.scss";
.edition {
  position: relative;
  .edition-text {
    @include typoD;
    overflow:hidden;
  }
}
.edition-bloc {
  position:relative;
  border-top: 1px solid #000;
  padding:0 0 30px 0;
  & img {
    max-width: 100%;
  }
  &:hover {
    color:$hover;
    a {
      color:$hover;
    }
    img {
      filter:saturate(1.2);
    }
    border-top: 1px solid $hover;
  }
}
.edition-diaporama {
  &>img {
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    max-width: 100%;
  }
  margin-top:15px;
  width:100%;
  padding-bottom:56.25%;
  position: relative;
  &>div.swiper-container {
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:calc(100% + 1.5em);
  }
  & .swiper-slide img {
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    object-fit: contain;
    object-position: center;
  }
  .images-player-compteur {
    position:absolute;
    top:5px;
    right:5px;
    cursor:pointer;
    border-radius: 4px;
    color:#FFF;
    text-shadow: 0 0 10px #000;
    padding:5px;
    z-index:10;
    user-select: none;
    @include typoF;
  }
}
