@import "../../utils/variables.scss";
.biographie {
  border-top: 1px solid #000;
  padding:5px 0;
  margin-top:30px;
  overflow:hidden;
}
.biographie-titre {
  @include typoE;
}
.biographie-nom {
  @include typoB;
}
.biographie-text {
  @include typoD;
}
