@import "../../utils/variables.scss";
.topbar {
  position: sticky;
  z-index:100;
  top:-84px;
  width:100%;
  height:144px;
  background-color: #FFF;
  .topbar-inner {
    position:absolute;
    top:0;
    left:140px;
    width: calc( 100% - 280px );
    @include xl {
        left:50px;
        width: calc( 100% - 100px );
    }
    @include lg {
        left:15px;
        width: calc( 100% - 30px );
    }
    height:100%;
    &>div {
        position:sticky;
        top:-30px;
        left:0;
        width:100%;
        height:72px;
        &>div {
          height:100%;
          &>div {
            height:100%;
            position:relative;
          }
        }
        a {
          text-decoration: none;
          color: inherit;
          &.topbar-mainlink {
            position:absolute;
            bottom:0;
            font-size:35px;
            line-height: 36px;
            font-weight:800;
            z-index:12;
          }
          &.playlist-link {
            left:0;
          }
          &:hover {
            color:$hover;
          }
          &.front-link {
            right:8px;
            bottom:-18px;
            padding:10px 0;
            &:hover {
              padding:10px;
              color:#FFF;
              background-color:#000;
            }
          }
        }
        &.topbar-border {
          position:absolute;
          width:100%;
          bottom:-1px;
          top:unset;
          &>div>div {
            position:relative;
            padding: 0;
            &>div {
              position:absolute;
              width:calc(100% - 16px);
              bottom:-8px;
              left:8px;
              border-bottom: 1px solid #000;
            }
          }
        }
    }
    &>div.xs {
        top:0;
        height:144px;
        .xs-title {
            position:sticky;
            top:10px;
            z-index:2;
            height:30px;
        }
        .xs-utils {
            z-index:1;
        }
    }
  }
}
.topbar .topbar-inner > div.xs > div {
    height:unset;
}
