@import "../../utils/variables.scss";
.ressources {
  border-top: 1px solid #000;
  padding:5px 0;
  margin-top:30px;
  & a:hover {
    color:$hover;
    svg {
      fill:$hover;
    }
  }
}
.ressources-titre {
  @include typoE;
}
.ressources-nom {
  @include typoB;
}
.ressources-documents {
  @include typoD;
}
