@import "../../utils/variables.scss";
.editions {
  position:relative;
  .editions-filtres {
    position:sticky;
    top:60px;
  }
  & .filter-type {
    @include typoB;
    cursor:pointer;
    user-select: none;
    & svg .to-stroke {
      stroke:#000;
    }
    &.active svg .active-fill {
      fill:$hover;
    }
    &:hover{
      color:$hover;
      & svg .to-stroke {
        stroke:$hover;
      }
    }
    &.archives {
      margin-top:30px;
    }
  }
}
