@import "../../utils/variables.scss";
.page {
  position: relative;
  .page-text {
    @include typoD;
    a {
      text-decoration: underline;
    }
  }
}
