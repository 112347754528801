@import "../../utils/variables.scss";
.blog {
  position: relative;

  & .blog-dates {
    @include typoE;
  }
  & .blog-texte {
    @include typoD;
    a {
      text-decoration: underline;
    }
    overflow:hidden;
  }
  & .blog-spacer {
    @include typoE;
  }
  & .blog-type {
    @include typoE;
  }
  & .blog-titre {
    @include typoB;
  }
  & .blog-artistes {
    @include typoB;
  }
  & .blog-mini-titre {
    @include typoB;
  }
  & .blog-type {

  }
  & .blog-visuel {
    padding:10px 0;
  }
  & .blog-lieu {
    @include typoB;
  }
  & .blog-mini-lieu {
    margin-top: 5px;
    @include typoC;
  }
  & .blog-ville {
    @include typoB;
  }
  & .blog-resume {
    @include typoD;
  }
  & .blog-mini-resume {
    @include typoE;
    & p:first-child {
      margin-top:5px;
    }
  }
}
.blogs {
  position:relative;
  & .filter-type {
    @include typoB;
    cursor:pointer;
    user-select: none;
    & svg .to-stroke {
      stroke:#000;
    }
    &.active svg .active-fill {
      fill:$hover;
    }
    &:hover{
      color:$hover;
      & svg .to-stroke {
        stroke:$hover;
      }
    }
    &.archives {
      margin-top:30px;
    }
  }
}
