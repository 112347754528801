@import "../../utils/variables.scss";
.eac-docs {
  position:relative;
  min-height:200vh;
  .eac-ressource-titre {
    @include typoA;
    padding:5px 0;
    margin-bottom:15px;
  }
  .eac-ressource-type-titre {
    @include typoB;
  }
  .eac-ressource-doc {
    @include typoE;
    padding-top: 10px;
    .eac-ressource-doc-titre {
      @include typoD;
      text-decoration: underline;
    }
  }
  .eac-ressource-type {
    border-top: 1px solid #000;
    margin-bottom:50px;
  }
}
