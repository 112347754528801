@import "../../utils/variables.scss";
.residence {
  position:relative;

  .billets {
    position:sticky;
    top:60px;
  }
}
.residence-bloc {
  border-top: 1px solid #000;
  position:relative;
  padding:0 0 30px 0;
  &.mini {
    padding:0;
  }
  & img {
    max-width: 100%;
  }
  &:hover {
    color:$hover;
    border-top: 1px solid $hover;
  }
}
.residence-text {
  @include typoD;
  overflow:hidden;
  & a {
    text-decoration: underline;
  }
}
.residence-diaporama {
  margin-top:15px;
  width:100%;
  position: relative;
  &>div.swiper-container {
    position: relative;
    width:100%;
  }
  & .swiper-slide img {
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    object-fit: contain;
    object-position: center;
  }
  .images-player-compteur {
    position:absolute;
    top:5px;
    right:5px;
    cursor:pointer;
    border-radius: 4px;
    color:#FFF;
    text-shadow: 0 0 10px #000;
    padding:5px;
    z-index:10;
    user-select: none;
    @include typoF;
  }
}
