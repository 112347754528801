@import "utils/variables.scss";
.main-container {
    margin: 0 100px;
}
.body-container {
    margin-left:140px;
    width: calc( 100% - 280px );
    @include xl {
        margin-left:50px;
        width: calc( 100% - 100px );
    }
    @include lg {
        margin-left:15px;
        width: calc( 100% - 30px );
    }
    min-height: calc( 100vh - 144px);
    user-select:none;
}
a {
    color:#000;
    text-decoration: none;
    &:hover {
      color:$hover;
    }
}
.icon-download svg {
  position:relative;
  top:3px;
  width:25px;
  height:14px;
  & path {
    fill:#000;
  }
  &:hover path {
    fill:$hover;
  }
}
hr {
  height:1px;
  background-color: #000;
  border:none;
  margin : 0;
}
.spacer {
  height:15px;
}
.tile-link {
  position:relative;
  top:-1px;
}
.swiper-container {
  .image-credits {
    text-align:center;
  }
  & .swiper-button-prev, & .swiper-button-next {
    color: #000;
  }
  & .swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
    opacity:1;
    color: #FFF;
  }
  & .swiper-pagination-bullet {
    opacity:1;
    background-color: #FFF;
    box-shadow: 0 0 30px #FFF8;
  }
  & .swiper-button-prev, & .swiper-button-next, & .swiper-pagination, & .swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
    opacity:0;
    transition: opacity 0.5s;
    @include xs {
      opacity:1;
    }
  }
  &:hover {
      & .swiper-button-prev, & .swiper-button-next, & .swiper-pagination, & .swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
        opacity:1;
      }
  }
}
.type-billet {
  @include typoC;
}
