@import "../../utils/variables.scss";
.share-container {
  width:100%;
  position:relative;
  margin-top:10px;
  text-align: right;
  .share {
    display: inline-block;
    width:22px;
    height:28px;
    cursor:pointer;
    svg {
      width:100%;
      height:100%;
      fill:#000;
      stroke:#000;
    }
    &:hover {
      svg {
        fill:$hover;
        stroke:$hover;
      }
    }
  }
  .share-popup {
    position:absolute;
    pointer-events: none;
    opacity:0;
    bottom:-30px;
    right:30px;
    z-index:1000;
    &.show {
      pointer-events: all;
      opacity:1;
    }
    .share-inner {
      margin:15px 0;
      background:#FFF;
      border-top:1px solid #000;
      box-shadow: 0 0 5px #0002;
      display:flex;
      padding:10px 10px;
      .share-item {
        padding:0px 5px;
      }
    }
  }
}
