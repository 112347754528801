@import "../../utils/variables.scss";
.topbar .topbar-inner > div > div > div.menu-mobile {
  position:absolute;
  right:0;
  top:0;
  width:100%;
  height: 0;
  .menu-mobile-btn {
    position:absolute;
    right:0;
    top:0;
    height:30px;
    width:30px;
    text-align: right;
    z-index:103;
    cursor:pointer;
  }
  .menu-mobile-over {
    position:absolute;
    right:-15px;
    top:30px;
    width:calc(100% + 30px);
    height:calc(100vh - 30px);
    background-color: #FFF;
    z-index:102;
    .menu-mobile-over-inner {
      position:absolute;
      right:0;
      top:30px;
      width:100%;
      height:100%;
      background-color: #FFF;
      box-sizing: border-box;
      overflow-y: scroll;
      .menu-left {
        position:absolute;
        top:0px;
        left:15px;
        width:calc(100% - 30px);
      }
      .menu-mobile-item-option {
        position:absolute;
        @include typoC;
        padding:15px 0 15px 30px;
        right:0;
        top:5px;
        &.active {
          color:$hover;
        }
      }
      .menu-mobile-surtitre {
        margin-top: 30px;
        @include typoE;
      }
      .menu-mobile-item {
        @include typoB;
        border-top: 1px solid #000;
        padding:15px 30px 15px 0;
        position: relative;
        &.active {
          color:$hover;
        }
        &>div.infos {
          a {
            text-decoration: none;
            color:#FFF;
            &:hover {
              color:$hover;
            }
          }
          @include typoF;
        }
        &>div.picto {
          position:absolute;
          right:0;
          top:12px;
          width: 26px;
          height: 26px;
          // svg {
          //   width:100%;
          //   height:100%;
          // }
        }
        &:hover {
          color:$hover;
          &>div {
            border-color:$hover;
            svg .to-fill {
              fill:$hover;
            }
            svg .to-stroke {
              stroke:$hover;
            }
          }
        }
        &.active {
          &>div {
            svg .active-fill {
              fill:$hover;
            }
          }
        }
        .menu-mobile-item-pages {
          color:#000;
          position:absolute;
          display:none;
          top:-1px;
          left:100%;
          background:#FFF;
          z-index:10;
          min-width:100%;
        }
        &:hover .menu-mobile-item-pages{
          display:block;
        }
      }
      // &.mini {
      //   top:50px;
      // }
      .filtres {
        .MuiGrid-item {
          position: relative;
        }
        .MuiIconButton-root {
          position:absolute;
          top:-2px;
          left:0;
          padding:0;
        }
      }
    }
  }
}
