@import "../../utils/variables.scss";
.pagination {
    position:relative;
    top:0;
    &.sticky {
      position:sticky;
      top:60px;
    }
    @include typoE;
    padding: 10px 0;
    background-color: #FFF;
    z-index:10;
    border-bottom: 1px solid #000;
    & span.chevron {
      cursor:pointer;
      &:hover {
        color:$hover;
      }
      padding:10px 5px;
      user-select: none;
    }
    & span.page {
      display:inline-block;
      min-width:6em;
      text-align:center;
      user-select: none;
    }
    & .pagination-right {
      float:right;
    }
    &.mini {
      padding: 2px 0;
      border-bottom: none;
      &.sticky {
        border-bottom: 1px solid #000;
      }
    }
}
