@import "../../utils/variables.scss";
.rubrique {
  position: relative;
  .page-item {
    @include typoB;
  }
  .rubrique-title-small {

    @include typoE;
  }
}
