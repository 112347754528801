@import "../../utils/variables.scss";
.partenaires {
  border-top: 1px solid #000;
  padding:5px 0;
  margin-top:30px;
  & a:hover {
    color:$hover;
    svg {
      fill:$hover;
    }
  }
  .partenaires-list {
    @include typoD;
    display:flex;
    flex-wrap: wrap;
    .partenaire {
      display:inline-block;
      min-width:300px;
      margin-right:30px;
      img {
        margin: 10px 0 30px 0;
      }
    }
  }
}
.partenaires-titre {
  @include typoE;
}
.partenaires-nom {
  @include typoB;
}
