@import "../../utils/variables.scss";
.rdv-tile {
  border-top: 1px solid #000;
  padding:5px 0;
  position:relative;
  .rdv-tile-head {
    @include typoB;
    display:flex;
    justify-content: flex-end;
    flex-direction: column;
  }
  .rdv-tile-type {
    @include typoE;
  }
  .rdv-tile-titre {
    @include typoB;
    margin-bottom:8px;
    max-height:3.2em;
    overflow: hidden;
  }
  .rdv-tile-mini-titre {
    @include typoB;
  }
  .rdv-tile-artiste {
    @include typoB;
    margin-bottom:8px;
    max-height:3.2em;
  }
  .rdv-tile-mini-artiste {
    @include typoB;
  }
  .rdv-tile-type {

  }
  .rdv-tile-visuel {
    padding:10px 0;
  }
  .rdv-tile-dates {
    @include typoB;
    margin-top:8px;
  }
  .rdv-tile-mini-dates {
    @include typoD;
  }
  .rdv-tile-lieu {
    @include typoB;
  }
  .rdv-tile-mini-lieu {
    margin-top: 5px;
    @include typoC;
  }
  .rdv-tile-ville {
    @include typoB;
  }
  .rdv-tile-resume {
    @include typoD;
  }
  .rdv-tile-mini-resume {
    @include typoE;
    p:first-child {
      margin-top:5px;
    }
  }
  &:hover {
    color:$hover;
    a {
      color:$hover;
    }
    img {
      filter:saturate(1.2);
    }
    border-top: 1px solid $hover;
  }
}
