@import "../../utils/variables.scss";
.menu-left {
  width:100%;
  position: sticky;
  z-index: 11;
  top:60px;
  // transition: top 0.5s;
  padding-bottom:30px;
  & .menu-left-surtitre {
    margin-top: 30px;
    @include typoE;
  }
  .active {
    color:$hover;
  }

  & .menu-left-item {
    cursor:pointer;
    @include typoB;
    &.active {
      &>a {
        color:$hover;
      }
    }
    border-top: 1px solid #000;
    padding:15px 0;
    box-sizing: border-box;
    position: relative;
    &>div.infos {
      & a {
        text-decoration: none;
        color:#FFF;
        &:hover {
          color:$hover;
        }
      }
      @include typoF;
    }
    &>div.picto {
      position:absolute;
      right:0;
      top:12px;
      width: 26px;
      height: 26px;
      // & svg {
      //   width:100%;
      //   height:100%;
      // }
    }
    &:hover {
      color:$hover;
      &>div {
        border-color:$hover;
        & svg .to-fill {
          fill:$hover;
        }
        & svg .to-stroke {
          stroke:$hover;
        }
      }
    }
    &.active {
      &>div {
        & svg .active-fill {
          fill:$hover;
        }
      }
    }
    .menu-left-item-btn {
      display: flex;
      align-items: center;
      .text {
        flex-grow: 1;
      }
      .picto {
        width:22px;
        height:28px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        &.open {
          background-image: url(../../imgs/open.svg);
        }
        &.closed {
          background-image: url(../../imgs/Close.svg);
        }
      }
    }
  }
  .menu-left-item-pages {
    .menu-left-item {
      padding-left:30px;
    }
  }
  // &.mini {
  //   top:50px;
  // }
  & .filtres {
    & .MuiGrid-item {
      position: relative;
    }
    & .MuiIconButton-root {
      position:absolute;
      top:-2px;
      left:0;
      padding:0;
    }
  }
}
